<template>
  <div class="about-us">
    <!-- 头部 -->
    <van-nav-bar
      title="关于云楚新能源"
      left-arrow
      @click-left="onClickLeft"
      class="dark_bg"
    />
    <section>
      <p style="margin-top: 1rem">
        <img src="../../../assets/images/logo2.png" alt />
        <span>当前版本@{{ $store.state.user.version }}</span>
      </p>
    </section>
    <van-cell-group>
      <!-- <van-cell title="云楚新能源简介" is-link /> -->
      <!-- <van-cell title="使用说明" is-link /> -->
      <van-cell title="服务协议" is-link @click="goAgree()" />
      <van-cell title="隐私政策" is-link @click="goPrivacyPolicy()" />
    </van-cell-group>
    <div class="aboutus-footer">
      <p>云楚新能源 Copyright © 2019</p>
      <p>深圳云楚新能源电子商务有限公司</p>
    </div>
    <div class="relation">
        --- 联系我们 : 0871-65353806 ---
    </div>
  </div>
</template>

<script>
import { NavBar, Cell, CellGroup } from "vant";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  methods: {
    onClickLeft() {
      this.$router.back(0);
    },
    goPrivacyPolicy() {
      this.$router.push({
        path: "/privacypolicy",
      });
    },
    goAgree() {
      this.$router.push({
        path: "/agree",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.about-us {
  width: 100%;
  height: 100vh;
  // background-color: #f0eded;

  box-sizing: border-box;
  .van-nav-bar {
    color: #373737;
    letter-spacing: 1px;
    .van-icon {
      color: #373737;
    }
  }
  & > section {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    p {
      width: 40%;
      text-align: center;
      font-size: 0.3rem;
      letter-spacing: 1px;
      color: #808080;
    }
    img,
    span {
      display: block;
      line-height: 1rem;
    }
    img {
      width: 100%;
    }
  }
  & > .aboutus-footer {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 0.8rem 0.8rem 0.2rem 0.8rem;
    color: #4c4747;
    p {
      font-size: 0.3rem;
      line-height: 0.5rem;
    }
  }
}
.relation{
  text-align: center;
  font-size: .26rem;
  color: #aaa;
}
</style>